(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/hooks/assets/javascripts/use-race.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/hooks/assets/javascripts/use-race.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  sportProducts
} = svs.components.lbUtils;
const {
  selectors
} = svs.components.marketplaceData;
const useRace = (activatedDrawId, groupId) => {
  const game = useSelector(state => selectors.games.getGame(state, {
    activatedDrawId,
    groupId
  }));
  const draw = useSelector(state => selectors.draws.getDraw(state, {
    drawNumber: game.drawNumber,
    productName: sportProducts.getProdName(game.productId)
  }));
  const safeDraw = draw || {};
  const race = useSelector(state => selectors.racing.selectRace(state, safeDraw.drawNumber));
  const meeting = useSelector(state => selectors.racing.selectMeeting(state, safeDraw.drawNumber));
  return {
    race,
    meeting,
    draw
  };
};
setGlobal('svs.components.marketplaceShared.hooks.useRace', useRace);

 })(window);